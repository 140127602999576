:root {
	--about-blue: #479fd6;
	--about-dark-blue: #34627d;
}

.about-page__description {
	font-size: 22px;
	text-align: justify;
	position: relative;
}

.about-page__description::before {
	position: absolute;
	content: '';
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	left: -10px;
	height: 100%;
	width: 5px;
	background-color: var(--about-blue);
	margin-right: 10px;
	transition: height 250ms ease-in;
}

.about-page__description:hover::before {
	height: 110%;
	transition: height 250ms ease-in;
}

.about-card {
	margin: 0 auto;
	max-width: 400px;
	aspect-ratio: 3/2;
	border: 1px solid var(--about-dark-blue);
	word-wrap: normal;
	transition: box-shadow 350ms ease-in-out;
}

.about-card:hover,
.about-page__image-wrap:hover img {
	box-shadow: 0 0 20px var(--about-dark-blue);
	transition: box-shadow 350ms ease-in-out;
}

.about-card i {
	padding: 5px;
	font-size: 45px;
	color: var(--about-blue);
}

.about-page__card-title {
	color: var(--about-dark-blue);
}

.about-page__image-wrap img {
	transition: box-shadow 350ms ease-in;
}

.parent {
	display: grid;
	height: 70vw;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(12, 1fr);
	grid-column-gap: 5px;
	grid-row-gap: 5px;
}
.div1 {
	grid-area: 1 / 2 / 4 / 3;
}
.div2 {
	grid-area: 4 / 2 / 7 / 3;
}
.div3 {
	grid-area: 1 / 1 / 7 / 2;
}
.div4 {
	grid-area: 1 / 3 / 4 / 4;
}
.div5 {
	grid-area: 7 / 1 / 13 / 2;
}
.div6 {
	grid-area: 4 / 3 / 7 / 4;
}
.div7 {
	grid-area: 7 / 3 / 10 / 4;
}
.div8 {
	grid-area: 7 / 2 / 13 / 3;
}
.div9 {
	grid-area: 10 / 3 / 13 / 4;
}

@media (max-width: 992px) {
	.parent {
		height: 110vh;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(18, 1fr);
	}
	.div1 {
		grid-area: 1 / 2 / 4 / 3;
	}
	.div2 {
		grid-area: 4 / 2 / 7 / 3;
	}
	.div3 {
		grid-area: 1 / 1 / 7 / 2;
	}
	.div4 {
		grid-area: 7 / 2 / 10 / 3;
	}
	.div5 {
		grid-area: 7 / 1 / 13 / 2;
	}
	.div6 {
		grid-area: 10 / 2 / 13 / 3;
	}
	.div7 {
		grid-area: 13 / 2 / 16 / 3;
	}
	.div8 {
		grid-area: 13 / 1 / 19 / 2;
	}
	.div9 {
		grid-area: 16 / 2 / 19 / 3;
	}
}

@media (max-width: 768px) {
	.parent {
		height: 150vh;
	}
}

@media (max-width: 576px) {
	.parent {
		height: 130vh;
	}
}
