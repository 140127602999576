.swiper {
	width: 65%;
	height: 100%;
	overflow: hidden;
}

.swiper-slide {
	text-align: center;
	font-size: 18px;
	background: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
}

.swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: transform 350ms ease-in-out;
}

.producerSlider .swiper-slide img:hover {
	transform: scale(1.2);
}
