.styled-image {
	position: absolute;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.loading-image {
	width: 100%;
	height: 100%;
	background-color: #bebebe;
	background: linear-gradient(
			100deg,
			rgba(255, 255, 255, 0) 40%,
			rgba(255, 255, 255, 0.5) 50%,
			rgba(255, 255, 255, 0) 60%
		)
		#bebebe;
	background-size: 200% 100%;
	background-position-x: 180%;
	animation: 1.5s loading ease-in-out infinite;
}

@keyframes loading {
	to {
		background-position-x: -30%;
	}
}
