:root {
	--product-detail-blue: #479fd6;
	--product-detail-darkblue: #4682a4;
	--product-detail-link: #696969;
	--product-detail-link-hover: #444444;
}

.product-detail__skeleton-loading {
	border-radius: 5px;
	background-color: #bebebe;
	background: linear-gradient(
			100deg,
			rgba(255, 255, 255, 0) 40%,
			rgba(255, 255, 255, 0.5) 50%,
			rgba(255, 255, 255, 0) 60%
		)
		#bebebe;
	background-size: 200% 100%;
	background-position-x: 180%;
	animation: 1.5s loading ease-in-out infinite;
}

@keyframes loading {
	to {
		background-position-x: -30%;
	}
}

.blue-left {
	position: relative;
}

.blue-left::before {
	content: '';
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	height: 25px;
	width: 2px;
	background-color: var(--product-detail-blue);
}

.product-detail__product-name {
	font-weight: bold;
	color: var(--product-detail-darkblue);
}

.product-detail__product-article {
	font-weight: bold;
}

.product-detail__description {
	text-align: justify;
	font-size: 18px;
}

.product-detail__upperpanel {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.breadcrumb {
	margin: 0 !important;
}

.breadcrumb-item {
	.product-detail__link {
		color: var(--product-detail-darkblue);
		transition: color 250ms ease-in-out;
	}
}

.product-detail__link {
	color: var(--product-detail-link);
	font-size: large;
	text-decoration: none;
}

.product-detail__link:hover {
	color: var(--product-detail-link-hover);
	text-decoration: underline;
}

.product-detail__back {
	color: var(--product-detail-darkblue);
	background-color: transparent;
	border: none;
	font-size: large;
}

.characteristic__wrapper {
	border-bottom: 2px solid var(--product-detail-blue);
}

.characteristic__wrapper:first-child {
	border-top: 2px solid var(--product-detail-blue);
}

.characteristic__name {
	word-wrap: break-word;
	width: 25%;
	max-width: 400px;
	font-weight: bold;
	padding: 15px 25px;
	font-size: 18px;
}

.characteristic__value {
	width: 25%;
	max-width: 400px;
	margin-left: auto;
	padding: 15px 25px;
	font-size: 18px;
}

@media (max-width: 768px) {
	.product-detail__upperpanel {
		flex-direction: column;
		gap: 15px;
	}
	.product-detail__back {
		margin-left: 0;
		margin-right: auto;
	}
	.characteristic__name,
	.characteristic__value {
		width: 45%;
	}
}

@media (max-width: 576px) {
	.characteristic__name,
	.characteristic__value {
		width: 50%;
		padding: 15px 10px;
	}
}
