* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: 'Open Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Geologica', sans-serif;
}

span,
div,
p,
a {
	font-family: 'Roboto', sans-serif;
}

#root {
	display: flex;
	min-height: 100vh;
	margin: 0;
	flex-direction: column;
}

main {
	flex: 1;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
