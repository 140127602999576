:root {
	--link-color: #696969;
	--link-hovered: #444444;
	--card-shadow: #2a6285;
}

.styled-card {
	border-radius: 15px !important;
	transition: box-shadow 350ms ease-in-out;
}

.styled-card:hover {
	box-shadow: 0 0 20px var(--link-hovered);
	transition: box-shadow 350ms ease-in-out;
}

.new-items__tables-row .styled-card:hover {
	box-shadow: 0 0 20px var(--card-shadow);
}

.category-link {
	color: var(--link-color);
	font-size: large;
	text-decoration: none;
}

.category-link:hover {
	color: var(--link-hovered);
	text-decoration: underline;
}

.category-card__image-wrap {
	width: 100%;
	aspect-ratio: 1;
}
